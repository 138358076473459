// 1999 ARG
document.addEventListener("DOMContentLoaded", function() {
    const logo = document.querySelector('.Masthead-logoImage');
    const logoContainer = document.querySelector('.Masthead-logo');
    let isOriginal = true;

    const {
        originalLogo,
        alternateLogo,
        originalGlitchImages,
        alternateGlitchImages,
        alternateHref
    } = window.homeArgData;

    logoContainer.addEventListener('click', function() {
        if (!isOriginal) {
            window.open(alternateHref, '_blank');
        }
    });

    function glitchEffect(callback) {
        let glitchIndex = 0;
        const glitchInterval = setInterval(function() {
            logo.classList.add('glitch-opacity');

            const current = isOriginal ? originalGlitchImages : alternateGlitchImages;
            logo.src = current[glitchIndex];
            glitchIndex++;

            if (glitchIndex >= current.length) {
                clearInterval(glitchInterval);
                logo.classList.remove('glitch-opacity');
                callback();
            }
        }, 100);
    }

    function switchLogo() {
        glitchEffect(function() {
            setTimeout(function() {
                logo.src = isOriginal ? alternateLogo : originalLogo;
                logo.classList.toggle('alt-logo');
                logoContainer.style.cursor = isOriginal ? 'pointer' : 'default';
                isOriginal = !isOriginal;
            }, 100);
        });
    }

    setInterval(switchLogo, 5000);
});